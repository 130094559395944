/* Container for centering */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #101010; /* Black background */
  perspective: 800px; /* Perspective for 3D effect */
}

/* Cube styling */
.cube {
  position: relative;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  animation: rotate 2s infinite linear; /* Continuous 3D rotation */
}

/* Cube faces styling */
.face {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: white; /* Base color for cube faces */
  opacity: 0.9; /* Slight transparency for 3D effect */
  border: 1px solid #111; /* Subtle border for definition */
}

/* Individual face positions */
.front  { transform: translateZ(40px); }
.back   { transform: rotateY(180deg) translateZ(40px); }
.left   { transform: rotateY(-90deg) translateZ(40px); }
.right  { transform: rotateY(90deg) translateZ(40px); }
.top    { transform: rotateX(90deg) translateZ(40px); }
.bottom { transform: rotateX(-90deg) translateZ(40px); }

/* 3D rotation animation */
@keyframes rotate {
  from { transform: rotateX(0deg) rotateY(0deg); }
  to { transform: rotateX(360deg) rotateY(360deg); }
}
